import React, { useEffect, useRef, useState } from "react";
import cs_logo from "../../assets/icons/cs-logo.svg";
import cs_footer_log from "../../assets/icons/cs-footer-logo.svg";

import { Link, useLocation } from "react-router-dom";
import CustomBtn from "../primitive/CustomBtn";
import MobileMenu from "./MobileMenu";

const Navbar = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const isBlogPostDetails = location.pathname.startsWith("/blog");

  return (
    <div
      className={
        location.pathname === "/"
          ? "border-b-[0.1px] border-b-white"
          : "border-b-[0.1px] border-b-Liver"
      }
    >
      <div className="pt-1 max-w-[90rem] w-full mx-auto">
        <div className="flex items-center px-[1.5rem] md:px-10 justify-between py-2.5">
          <Link to="/">
            {location.pathname === "/privacy-policy" || isBlogPostDetails ? (
              <img src={cs_footer_log} className="w-28" alt="" />
            ) : (
              <img src={cs_logo} alt="" />
            )}
          </Link>
          <div className="xl:hidden">
            <MobileMenu />
          </div>
          <div className="hidden xl:block">
            <div className="flex items-center space-x-2 xl:space-x-8">
              <div
                className={
                  location.pathname === "/privacy-policy" || isBlogPostDetails
                    ? "flex items-center space-x-2 lg:space-x-4 text-black font-source-sans-pro-extralight text-[14px]"
                    : "flex items-center space-x-2 lg:space-x-4 text-white font-source-sans-pro-extralight text-[14px]"
                }
              >
                <Link
                  to="/"
                  className={
                    location.pathname === "/"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Home
                </Link>
                <Link
                  to="/strategy-and-risk"
                  className={
                    location.pathname === "/strategy-and-risk"
                      ? "raise border-b-[0.1px]"
                      : "raise m-0"
                  }
                >
                  Strategy and Risk Management
                </Link>
                <Link
                  to="/cybersecurity-transformation"
                  className={
                    location.pathname === "/cybersecurity-transformation"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Cybersecurity Transformation
                </Link>
                <Link
                  to="/cybersecurity-testing"
                  className={
                    location.pathname === "/cybersecurity-testing"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Cybersecurity Testing
                </Link>
                <Link
                  to="/data-analytics"
                  className={
                    location.pathname === "/data-analytics"
                      ? "raise border-b-[0.1px]"
                      : "w-fit"
                  }
                >
                  Data Analytics
                </Link>
                <Link
                  to="/blog"
                  className={
                    location.pathname === "/blog"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Article
                </Link>
              </div>
              <div className="-mb-3 relative inline-block" ref={dropdownRef}>
                <CustomBtn
                  onClick={toggleDropdown}
                  text="Request a service"
                  green="none"
                  boxHover="0 0 0 0 #FFFFFF, inset 6em 4.5em 0 0 #FFFFFF"
                  boxBeforeHover="0.3em 0.3em 0 0 #FFFFFF, inset 0.3em 0.3em 0 0 #FFFFFF"
                  hoverTextColor=" #000000"
                  textColorBeforHover="#000000"
                  hoverBackgroundColor="#000000"
                  unHoverBackgroundColor="white"
                  paddingTop="1.2rem"
                  paddingBottom="1.2rem"
                  paddingLeft="1rem"
                  paddingRight="1rem"
                />

                <div
                  className={`${
                    isOpen
                      ? "opacity-100 translate-y-0"
                      : "opacity-0 translate-y-2"
                  } absolute w-full left-0 mt-2 bg-white border border-gray-300 shadow-lg rounded-md transition-all duration-300 ease-in-out z-10`}
                >
                  <div className="py-1">
                    <Link
                      to="/contact-us"
                      className="block px-4 py-3 text-gray-700 hover:bg-gray-100"
                    >
                      Contact Us
                    </Link>
                    <Link
                      to="/contact-us?toolkit=true"
                      className="block px-4 py-3 text-gray-700 hover:bg-gray-100"
                    >
                      Get Your Complete OSFI B-13 Toolkit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
