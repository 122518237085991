import React from "react";

const Testimonials = () => {
  return (
    <div className="md:pt-14 pt-8 pb-10 md:pb-24">
      <div className="container mx-auto space-y-8 md:space-y-16">
        <h1 className="max-w-[16rem] mx-auto md:max-w-fit text-3xl md:text-[4rem] text-center text-black font-source-sans-pro-light">
          Testimonials
        </h1>

        <div className="mx-auto md:flex justify-center md:gap-x-4">
          <div className="what_we_do_section_bg bg-white md:max-w-[26rem] border rounded-3xl space-y-5 text-center py-8 px-8 shadow-lg">
            <p className="text-sm md:text-lg">
              I would use Coolidge Solutions again and also recommend them to
              you. Their detailed practical security recommendations to our
              cloud penetration test vulnerabilities were very helpful
            </p>

            <h4 className="text-base md:text-2xl font-bold">
              CIO , A fintech firm in Quebec
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
