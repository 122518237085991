import React, { useEffect, useRef, useState } from "react";
import vision_and_mission_img from "../../assets/images/vision-and-mission-imgg.png";
import CustomBtn from "../primitive/CustomBtn";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div id="about-us">
      <div className="about_us_section_bg pt-20 pb-10 md:pb-14">
        <div className="container mx-auto space-y-12 md:space-y-20">
          <div className="flex justify-center">
            <h1 className="max-w-[16rem] md:max-w-fit text-3xl md:text-[4rem] text-center text-black font-source-sans-pro-light">
              About Coolidge Solutions
            </h1>
          </div>
          <div className="flex flex-col space-y-8 items-start  md:space-y-0 md:flex-row">
            <div className="md:w-[63%]  space-y-6 md:space-y-7">
              <p className="max-w-[35rem] font-source-sans-pro-extralight text-base md:text-xl md:text-left text-justify px-[1rem] md:px-6 lg:px-0">
                Coolidge Solutions is your trusted partner in navigating the
                complex intersection of cybersecurity and data analytics. With a
                relentless commitment to safeguarding digital landscapes and
                harnessing the power of data, we stand as a beacon of innovation
                and protection in today&apos;s rapidly evolving technological
                landscape.
              </p>
              <p className="max-w-[35rem] font-source-sans-pro-extralight text-base md:text-xl md:text-left text-justify px-[1rem] md:px-6 lg:px-0">
                We believe that a strong cybersecurity posture is the foundation
                of any thriving organization. Our cybersecurity services
                encompass an array of proactive measures, ranging from
                vulnerability assessments to incident response, ensuring that
                your digital assets remain shielded against a myriad of threats.
                At the same time, data is an invaluable asset that, when
                harnessed correctly, can drive remarkable growth. Our data
                analytics services unravel patterns and insights from your data,
                enabling you to make strategic decisions with a profound
                understanding of your business landscape.
              </p>
              <p className="max-w-[35rem] font-source-sans-pro-extralight text-base md:text-xl md:text-left text-justify px-[1rem] md:px-6 lg:px-0">
                As we embark on this journey with you, we stand committed to the
                principles of trust, innovation, and excellence. Your
                aspirations become ours, and your challenges become our driving
                force. Join us in shaping a more secure and data-driven future
                &ndash; where the digital realm becomes a realm of endless
                possibilities.
              </p>
            </div>
            <div className="md:w-[37%]">
              <img
                src={vision_and_mission_img}
                className=" w-full h-full"
                alt=""
              />
            </div>
          </div>
          <div className="block mx-auto mt-16 relative w-fit" ref={dropdownRef}>
            <CustomBtn
              onClick={toggleDropdown}
              text="Request a Service"
              green="none"
              boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
              boxBeforeHover="0.3em 0.3em 0 0 #000000, inset 0.3em 0.3em 0 0 #000000"
              hoverTextColor="white"
              paddingTop="1.5rem"
              paddingBottom="1.5rem"
              paddingLeft="3.5rem"
              paddingRight="3.5rem"
            />
            <div
              className={`${
                isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
              } absolute w-full left-0 mt-2 bg-white border border-gray-300 shadow-lg rounded-md transition-all duration-300 ease-in-out z-10`}
            >
              <div className="py-1">
                <Link
                  to="/contact-us"
                  className="block px-4 py-3 text-gray-700 hover:bg-gray-100"
                >
                  Contact Us
                </Link>
                <Link
                  to="/contact-us?toolkit=true"
                  className="block px-4 py-3 text-gray-700 hover:bg-gray-100"
                >
                  Get Your Complete OSFI B-13 Toolkit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
