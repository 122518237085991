import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/sections/Footer";
import Navbar from "../components/connected/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import share_icon from "../assets/icons/share-icon.png";
import whatsapp_icon from "../assets/icons/WhatsApp_icon.png";
import facebook_logo from "../assets/icons/Facebook_Logo.png";
import twitter from "../assets/icons/twitter.jpg";

const BlogPostDetails = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToOurServices = () => {
    if (ourServicesRef.current) {
      ourServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    axios
      .get(`https://blog.coolidgesolutions.com/api/blogs/${id}`)
      .then((response) => {
        setBlogPost(response.data);
        // console.log("lollipup", response.data);
      });
  }, [id]); // eslint-disable-next-line

  const postTitle = blogPost && blogPost[0] && blogPost[0]?.title;
  const postUrl = blogPost
    ? `https://coolidgesolutions.com/blog/${blogPost[0]?.id}`
    : // ? `https://coolidge-solutions.netlify.app/blog/${blogPost[0]?.id}`
      "";

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    postUrl
  )}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${postTitle}: ${postUrl}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    postUrl
  )}&text=${encodeURIComponent(postTitle)}`;

  if (!blogPost) {
    return (
      <div>
        <div className="bg-#391647">
          <Navbar />
        </div>
        <div className="container mx-auto py-8 md:py-32 space-y-6 animate-pulse">
          <div className="flex flex-col items-center justify-center space-y-4">
            <p className="text-3xl md:text-5xl text-center h-5 w-[80%] bg-slate-300"></p>
            <p className="text-3xl md:text-5xl text-center h-5 w-[60%] bg-slate-300"></p>
          </div>
          <div className="w-full">
            <div className="w-full h-56 md:h-96 rounded-2xl bg-slate-300"></div>
            <div className="flex items-center justify-between py-1.5 md:py-3">
              <div className="w-24 h-10 bg-slate-300  rounded-3xl"></div>
            </div>
          </div>
          <div className="space-y-2.5">
            <p className="w-full h-3 bg-slate-300"></p>
            <p className="w-[95%] h-3 bg-slate-300"></p>
            <p className="w-[90%] h-3 bg-slate-300"></p>
            <p className="w-[85%] h-3 bg-slate-300"></p>
            <p className="w-[80%] h-3 bg-slate-300"></p>
            <p className="w-[75%] h-3 bg-slate-300"></p>
            <p className="w-[70%] h-3 bg-slate-300"></p>
            <p className="w-[65%] h-3 bg-slate-300"></p>
            <p className="w-[60%] h-3 bg-slate-300"></p>
            <p className="w-[55%] h-3 bg-slate-300"></p>
            <p className="w-[50%] h-3 bg-slate-300"></p>
            <p className="w-[45%] h-3 bg-slate-300"></p>
            <p className="w-[40%] h-3 bg-slate-300"></p>
            <p className="w-[35%] h-3 bg-slate-300"></p>
            <p className="w-[30%] h-3 bg-slate-300"></p>
          </div>
        </div>
        <Footer
          scrollToAboutUs={scrollToAboutUs}
          scrollToOurServices={scrollToOurServices}
        />
      </div>
    );
  }
  return (
    <div>
      <div className="bg-#391647">
        <Navbar />
      </div>
      <div className="container mx-auto py-8 md:py-24 space-y-6">
        <h3 className="text-3xl md:text-5xl text-center">
          {blogPost[0]?.title}
        </h3>
        <div className="w-full">
          <div className="relative">
            <img
              src={blogPost[0]?.file}
              className="w-full h-56 md:h-96 object-cover rounded-2xl"
              alt={blogPost[0]?.title}
            />
            <span
              className="font-source-sans-pro-extralight text-xs text-white bg-black py-1 px-2 rounded-lg absolute top-4
         right-4"
            >
              {blogPost[0]?.label === 1
                ? "Politics"
                : blogPost[0]?.label === 2
                ? "Education"
                : ""}
            </span>
          </div>
          <div className="flex items-center justify-between py-1.5 md:py-3">
            <div className="flex items-center justify-center">
              <div className="relative mr-56">
                <div
                  className={`absolute inset-y-0 left-1/2 h-10 flex items-center pl-16 pr-3 space-x-2 bg-white rounded-tr-full rounded-br-full ${
                    isMenuOpen ? "" : "hidden"
                  }`}
                >
                  <a
                    href={facebookShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
                  >
                    <img src={facebook_logo} alt="" />
                  </a>
                  <a
                    href={twitterShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
                  >
                    <img src={twitter} alt="" />
                  </a>
                  <a
                    href={whatsappShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
                  >
                    <img src={whatsapp_icon} alt="" />
                  </a>
                </div>
                <button
                  className={`relative h-10 w-24 px-2 space-x-1 inline-flex items-center justify-center bg-VeryLightPurple hover:bg-opacity-80 rounded-tl-full rounded-bl-full focus:outline-none transition-all duration-500 ${
                    isMenuOpen
                      ? "bg-VeryLightPurple hover:bg-opacity-80"
                      : "rounded-tr-full rounded-br-full"
                  }`}
                  onClick={toggleMenu}
                >
                  <img src={share_icon} className="w-6" alt="" />
                  <span>Share</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>{ReactHtmlParser(blogPost[0]?.description)}</div>
        <div className="w-fit">
          <div className="relative mr-56">
            <div
              className={`absolute inset-y-0 left-1/2 h-10 flex items-center pl-16 pr-3 space-x-2 bg-white rounded-tr-full rounded-br-full ${
                isMenuOpen ? "" : "hidden"
              }`}
            >
              <a
                href={facebookShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
              >
                <img src={facebook_logo} alt="" />
              </a>
              <a
                href={twitterShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
              >
                <img src={twitter} alt="" />
              </a>
              <a
                href={whatsappShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
              >
                <img src={whatsapp_icon} alt="" />
              </a>
            </div>
            <button
              className={`relative h-10 w-24 px-2 space-x-1 inline-flex items-center justify-center bg-VeryLightPurple hover:bg-opacity-80 rounded-tl-full rounded-bl-full focus:outline-none transition-all duration-500 ${
                isMenuOpen
                  ? "bg-VeryLightPurple hover:bg-opacity-80"
                  : "rounded-tr-full rounded-br-full"
              }`}
              onClick={toggleMenu}
            >
              <img src={share_icon} className="w-6" alt="" />
              <span>Share</span>
            </button>
          </div>
        </div>
      </div>
      <Footer
        scrollToAboutUs={scrollToAboutUs}
        scrollToOurServices={scrollToOurServices}
      />
    </div>
  );
};

export default BlogPostDetails;
